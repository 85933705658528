@import "../../variables.scss";

.container {
    height: calc(100vh - 200px);
    box-sizing: border-box;
    // background-color: var(--background-color);
    background-color: rgb(20, 20, 20);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.linkContainer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  color: var(--primary-color);

  @include pad {
    width: 100%;
    margin: 0;
    padding: 4rem;
  }

  .title {
    font-size: var(--big-font-size);
    white-space: pre-line;
    text-align: center;
  }

  .text {
    white-space: pre-line;
  }

  .childContainer {
    z-index: 3;
    padding: 2rem;
    border: solid 1px var(--primary-color);
    border-radius: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;

    @include pad {
      background-color: #1e1c1c8f;
    }
  }

  .button {
    width: fit-content;
    background-color: transparent;
    color: var(--primary-color);
    border: solid 1px var(--primary-color);
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    font: inherit;
    opacity: 1 !important;
    text-decoration: none !important;
    transition: all 0.5s ease-in-out;

    &:hover {
      cursor: pointer;
      background-color: var(--primary-color);
      color: var(--secondary-color);
      // opacity: 1;
    }
  }
}


// .button {
//     background-color: var(--primary-color);
//     width: fit-content;
//     border: none;
//     padding: 0.5rem 1rem;
//     border-radius: 2rem;
//     font: inherit;
//     opacity: 0.7;
//     transition: all 0.2s ease-in-out;

//     &:hover {
//       cursor: pointer;
//       opacity: 1;
//       transition: all 0.2s ease-in-out;
//     }
// }

.background {
  height: 100%;
  width: fit-content;
  position: relative;
  inset: 0 0 100px black;
  z-index: 2;

  @include pad {
    position: absolute;
    right: 0;
  }

  .vignette {
    position: absolute;
    width: 70%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(20, 20, 20, 0));
    left: 0;
  }

  img {
    height: 100%;
  }
}