@import "../../variables.scss";

.container {
    height: calc(100vh - 76px);
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    background-color: var(--background-color);
    position: relative;
    z-index: 0;
}

.section {
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
    background-color: #D9D9D9;
}

.scrollDown {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 2rem;

    .svgContainer {
      width: 16px;
      height: 16px;
      // border-radius: 50%;
      // background-color: #000000;
      opacity: 0.5;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 16px;
        fill: var(--primary-color);
        animation: moveUpDown 1s linear infinite;
      }
    }

    @keyframes moveUpDown {
      0% {
        transform: translateY(0);
      }
      10% {
        transform: translateY(1px);
      }
      80% {
        transform: translateY(-1px);
      }
      100% {
        transform: translateY(0);
      }
    }
}

.firstSection {
  position: relative;
  background-image: url("../../images/home.JPG");
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 50vw rgba(0,0,0,1) inset;

  .topVignette {
    position: absolute;
    width: 100%;
    height: 20%;
    top: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(20, 20, 20, 0));
  }

  .bottomVignette {
    position: absolute;
    width: 100%;
    height: 15%;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(30, 28, 28, 0), rgba(30, 28, 28, 1));
  }
}

.secondSection {
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  color: var(--primary-color);
}

.title {
    font-size: var(--big-font-size);
    text-align: center;
    padding: 2rem 0;
}

.newestRelease {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 2rem;

    > div {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
  }
  

    @include pad {
      flex-direction: column;
      gap: 2rem;

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
}

.frame {
    width: 100%;
    min-height: 352px;
    aspect-ratio: 16/9;
    border-radius: 1rem;
    border: 0;
}