@import "../../variables.scss";

.container {
    height: calc(100vh - 200px);
    box-sizing: border-box;
    background-color: var(--background-color);
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
    @include pad {
      min-height: calc(100vh - 200px);
      height: fit-content;
      padding: 2rem;
    }
}

.title {
    font-size: var(--big-font-size);
    text-align: center;
    margin-bottom: 2rem;
}

.content {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    white-space: pre-line;
}

.flex {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;

  @include pad {
    padding: 2rem;
    flex-direction: column;
    gap: 2rem;
  }

  .logo {
    width: 300px;
    height: 100%;
    position: relative;

    @include pad {
      width: 200px;
      margin: 0 auto;
    }

    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include pad {
        position: relative;
        transform: none;
        top: 0;
        left: 0;
      }
    }
  }

}

.contactContainer {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: left;

    @include pad {
      margin: 0 auto;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      .svgContainer {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: var(--button-color);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

      @include pad {
        width: 40px;
        height: 40px;

        svg {
          height: 18px;
        }

        .special {
          width: 16px;
        }
      }
      
        svg {
          height: 26px;
          fill: var(--background-color);
        }
      
        .special {
          width: 25px;
          stroke: 4px;
        }
      
        &:hover {
          cursor: pointer;
        }
      }
    }
}


.linkContainer {
    background-color: var(--secondary-background-color);
    width: 25%;
    height: 50%;
    /* border-radius: 0.5rem; */
}

.button {
    position: relative;
    top: 80%;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: var(--primary-color);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font: inherit;
    font-size: small;
    opacity: 0.7;
    transition: all 0.2s ease-in-out;
}

.button:hover {
    cursor: pointer;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}