.container {
    width: 100vw;
    padding: 1.5rem 0;
    background-color: var(--tab-background-color);
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.contactContainer {
  margin: 0 auto;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
}

.svgContainer {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--button-color);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 20px;
    fill: var(--background-color);
  }

  .special {
    width: 18px;
  }

  &:hover {
    cursor: pointer;
  }
}
