@import "../../variables.scss";

.container {
  background-color: var(--background-color);
  color: var(--primary-color);
  box-sizing: border-box;
  padding: 2rem 0;
  position: relative;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: auto;
  align-items: center;
  justify-content: center;
  // min-height: 352px;
  &:not(:last-child) {
    padding-bottom: 2rem;
  }

  > * {
    width: 40%;
  }

  @include pad {
    flex-direction: column;
    padding: 1rem;
    
    > * {
      width: 100%;
      margin: 0;
    }
  }
}

.embed {
  // background-color: #D9D9D9;
  // border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  &.rightAlign {
    text-align: right;
  }
}


.title {
  font-size: var(--big-font-size);
  margin-bottom: 0.25rem;
  font-weight: bold;
}

.frame {
  /* width: 100%; */
  /* width: 40%; */
  // height: 352px;
  height: 352px;
  width: 100%;
  // height: 100%;
  border-radius: 1rem;
  border: 0;
  align-items: center;
  overflow: hidden;

  img {
    height: 100%;
  }

  &.fb {
    width: 100%;
    aspect-ratio: 500/280;
    height: auto !important;
    // height: 237px !important;
  }
}