@mixin desktop {
  @media (max-width: 1296px){
    @content;
  }
}

@mixin pad {
  @media (max-width: 864px){
    @content;
  }
}
@mixin mobile {
  @media (max-width: 480px){
    @content;
  }
}
  
:root {
    --background-color: #1E1C1C;
    --secondary-background-color: rgb(82, 82, 82);
    --button-color: #D9D9D9;
    --tab-background-color: #000000;
    --primary-color: #FFFFFF;
    --secondary-color: #000000;

    --big-font-size: 1.2em;
    --bigger-font-size: 1.5em;
}