@import "../../variables.scss";

.container {
    height: calc(100vh - 76px);
    overflow-y: scroll;
    // scroll-snap-type: y mandatory;
    background-color: var(--background-color);
    position: relative;
}

.section {
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
    background-color: #D9D9D9;

    @include desktop {
      height: fit-content;
    }
}

.title {
    font-size: var(--big-font-size);
    font-weight: bold;
}

.firstSection {
  background-image: url("../../images/about.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-color: rgb(161, 147, 151);
  position: relative;

  @include desktop {
    background-position: top right;
  }

  @include pad {
    background-image: none;
    // background-position: top right;
    // background-position: right;
  }

  .topVignette {
    position: absolute;
    top: 0;
    width: 100%;
    height: 20%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(161, 147, 151, 0));
  }
}

.secondSection {
    background-color: var(--background-color);
    color: var(--primary-color);

    .timeline {
      height: fit-content !important;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      box-sizing: border-box;
      padding: 2rem;
      gap: 2rem;
    }

    .performance {
      .year {
        font-weight: 700;
        font-size: var(--big-font-size);
        text-decoration: underline;
        text-underline-offset: 4px;
      }

      .perfTitle {
        font-weight: 700;
        font-size: var(--big-font-size);
      }
    }
}

.performance {
  width: 100%;
  text-align: center;

  .year {
    margin-bottom: 1rem;
  }

  .detail {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .title {
      
    }
  }
}

.textArea {
    position: relative;
    width: 40%;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    text-align: justify;

    @include desktop {
      left: 0%;
      top: 0%;
      padding: 2rem;
      transform: none;
    }

    @include pad {
      width: 100%;
      box-sizing: border-box;
    }
}

.textArea *:not(:last-child) {
    display: block;
    margin-bottom: 0.75rem; 
}

.line {
    width: 80%;
    height: 0.2rem;
    background-color: var(--primary-color);
    position: relative;
    margin: auto;
    top: 1.7rem;
}

.timeline {
    width: 80%;
    height: 2rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.circleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
}

.year {
    font-size: 1rem;
    font-weight: normal;
    transition: all 0.25s ease-in-out;
}

.chosenYear {
    font-size: var(--big-font-size);
    font-weight: bold;
}

.circle {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: var(--primary-color);
    z-index: 100;
}

.circle:hover {
    cursor: pointer;
}

.contentContainer {
    width: 80%;
    display: flex;
    flex-direction: row;
    margin: 2rem auto;
    justify-content: space-evenly;
}

.embed {
    background-color: #D9D9D9;
    border-radius: 1rem;
}

.contentContainer > div {
    width: 40%;
    height: 352px;
}