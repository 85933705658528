@import url('https://fonts.googleapis.com/css?family=Lora');
@import "./variables.scss";

.main {
  margin: 0;
  padding: 0;
  font-family: 'Lora';
  font-size: 18px;
  line-height: 28px;

  @include pad {
    font-size: 14px;
  }
}
