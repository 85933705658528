@import "../../variables.scss";

.magicFlex {
  display: flex;
  justify-content: center;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.container {
  width: 100%;
  background-color: var(--tab-background-color);
  color: var(--primary-color);
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: var(--bigger-font-size);

  > a {
    &:hover {
      text-decoration: none !important;
    }
  }
}

.navigation {
  display: flex;
  gap: 2rem;
}

a {
  color: inherit;
  text-decoration: none;
  opacity: 0.75;
  transition: all 0.3s ease-in-out;
}

.active {
  opacity: 1;
}

a:hover {
  color: inherit;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.burger {
  width: 1.5rem;
  height: 1.5rem;
  stroke-width: 2px;
  stroke: var(--primary-color);

  .top {
      transform: translateY(-22%);
      transition: all 0.5s ease-out;

      &.opened {
          transform: rotate(45deg);
          transform-origin: center;
      }
  }

  .middle {
      transition: all 0.5s ease-out;

      &.opened {
          transform: scaleX(0.1);
          transform-origin: center;
      }
  }

  .bottom {
      transform: translateY(22%);
      transition: all 0.5s ease-out;

      &.opened {
          transform: rotate(-45deg);
          transform-origin: center;
      }
  }
}

.menu {
  position: absolute;
  z-index: 100;
  width: 100vw;
  height: fit-content;
  top: 76px;
  left: 0;
  padding: 1rem 2rem 2rem 2rem;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  background-color: var(--tab-background-color);
  color: var(--primary-color);
}